import { BsEthernet } from "react-icons/bs";
import {FiWifi} from "react-icons/fi";
const RoomModal = ({room, isOpen, isModalClose}) => {
    // modal for the room
    if(!isOpen) {
        return null;
    }

    return(
        <div className="modalContainer d-center ">
            <div className="modal roomModal drop-shadow">
                <div className="header">
                    <button  className="d-center" onClick={isModalClose}>Back</button>
                    
                    <h6>{room.jobNumber}</h6>
                </div>
                <div id="cableTypeContainer">
                { room.wired ? (
                <div>
                    <BsEthernet className="wired"/>
                    <h6>Ethernet</h6>
                </div>
                ) : (
                <div>
                    <FiWifi className="wired"/>
                    <h6>WIFI</h6>
                </div>
                )}
                </div>
                <h1>{room.name}</h1>
                <p className="floorLvl"><b>Floor Level</b> {room.level}</p>
                <p className="description"><b>Description </b>{room.description}</p>

                
            </div>
        </div>
    )
}
export default RoomModal