import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";
import {BiSolidFridge} from "react-icons/bi";
import {BsFillBoxSeamFill} from "react-icons/bs";
import {PiCouchLight, PiBathtubThin, PiGarageThin}  from "react-icons/pi";
import {IoIosBed} from "react-icons/io";
import {MdTableBar} from "react-icons/md";
import RoomModal from "./RoomModal";

const RoomCard = ({room}) => {
    //card for the room gives the place that it is located
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => {
        setModalOpen(true);
        document.body.style.overflow = "hidden"
    }

    const closeModal = () => {
        setModalOpen(false);
        document.body.style.overflow = "auto"
    }
    const switchIcon = (room) => {
        // let room = room.name;
        let icon;
        switch (room) {
            case "Kitchen":
                icon = <BiSolidFridge/>
                break;
            case "Living Room":
                icon = <PiCouchLight/>;
                break;
            case "Bedroom":
                icon = <IoIosBed/>;
                break;
            case "Bathroom":
                icon = <PiBathtubThin/>;
                break;
            case "Garage":
                icon = <PiGarageThin/>
                break;
            case "Attic":
                icon = <BsFillBoxSeamFill/>;
                break;
            case "Dining Room":
                icon = <MdTableBar/>;
                break;
            default :
                break;
        }
        return icon;
    }
    
    return(
        <>
        <button className="roomCard drop-shadow">
        <div onClick={openModal}>
            <section>
                <p>{room.level} Floor</p>
                <div id="roomIcon">
                    {switchIcon(room.name)}
                </div>
                <h1>{room.name}</h1>
                
                <p className="description">{room.description}</p>
            </section>
        </div>
        </button>
         {isModalOpen &&(
            <RoomModal className="d-center"key={room.id} room={room} isOpen={isModalOpen} isModalClose={closeModal}/>
        )}
        </>
    )
}
export default RoomCard