import { Link, useLocation } from "react-router-dom"
import logo from "../../assets/Images/logo.png"

const Header = (props) => {
    const location = useLocation();

    if (location.pathname === "/" || location.pathname === "/auth") {
        return null;
      }
    return(
        <div>
        <header>
            <Link to="/home"><img src={logo} alt="citadel cyber logo" /></Link>
            {props.children}
        </header>
        </div>
    )
}
export default Header