function setThemes(themeName) {
  localStorage.setItem("theme", themeName);
  document.documentElement.className = themeName;
}

function keepTheme() {
  if (localStorage.getItem("theme")) {
    if (localStorage.getItem("theme") === "theme-dark") {
      setThemes("theme-dark");
    } else if (localStorage.getItem("theme") === "theme-light") {
      setThemes("theme-light");
    }
  } else {
    setThemes("theme-dark");
  }
}

export { setThemes, keepTheme };
// module.exports = {
//   setThemes,
//   keepTheme,
// };
