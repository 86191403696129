import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {keepTheme} from './utils/themes';
import Header from "./components/Header/Header";
import Nav from "./components/Header/Navbar";
import Footer from "./components/Footer";
import Page from "./components/Page/Page";
import './App.css'

function App() {

  const currentPage = useLocation().pathname;
  useEffect(()=> keepTheme())

  return (
    <div>
    <Header> 
      <Nav currentPage={currentPage} />
    </Header>
    <main>
      <Page currentPage={currentPage} />
    </main>
    <Footer />
  </div>
  )
}

export default App
