import "../assets/CSS/Admin.css";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { IoCloseCircleOutline, IoAddCircleSharp } from "react-icons/io5";
import { fetchAllHouseData, fetchAllUserData, deleteHouseAdminData, fetchOneUserData, deleteUserData} from "../utils/api";
import { withAuth } from "../utils/auth";

const Admin = () => {
    const {storedToken, userId} = withAuth();
    const [houses, setHouses] = useState(false);
    const [houseArr, setHouseArr] = useState([]);
    const [users, setUsers] = useState(false);
    const [userArr, setUserArr] = useState([]);
    const renderHouses = async () => {
      try { 
        let houseData = await fetchAllHouseData(storedToken);
        setHouseArr(houseData);
        setHouses(true);
        setUsers(false);
      } catch (err) {
        console.error("Error fetching user data:", err);
      }
      
    }

    // const colorChange = (button) = ()=> {
    //   button.classList.add('clicked');
    // }

    const renderUsers = async () => {
        try {
          let userData = await fetchAllUserData(storedToken);
          setUserArr(userData);
          setUsers(true);
          setHouses(false);
        } catch (err) {
          console.error("Error fetching user data:", err);
        }
        }  
    const deleteHouse = async (id) => {
          try {
            let admin = await fetchOneUserData(storedToken,userId);
            await deleteHouseAdminData(id, admin, storedToken)
            renderHouses();
          } catch (err) {
            console.error(err)
          }
    }
    const deleteUser = async (id) => {
        try {
          let admin = await fetchOneUserData(storedToken,userId);
          await deleteUserData(id, admin, storedToken)
          renderUsers();
        } catch (err) {
          console.error(err)
        }
  
    }

    return (
        <div className="admin-container">
            <button onClick={renderHouses}>
                Houses
                <FaArrowRight className="arrow" />
            </button>
            <div className={houses ? "houseTable" : "hide"}>
                <div className="close-icon" onClick={() => setHouses(false)}>
                    <IoCloseCircleOutline size={"2em"}/>
                </div>
                <table>
                    {/* Table content for Houses */}
                        <thead class="table-header">
                            <tr>
                                <th>ADDRESS</th>
                                <th>CITY</th>
                                <th>STATE</th>
                                <th>ZIPCODE</th>
                                <th>NUM OF ROOMS</th>
                                <th>EDIT</th>
                                <th>DELETE</th>
                            </tr>
                        </thead>
                        <tbody>
                        {houseArr.map((house)=>(
                            <tr key={house._id}>
                              <td>{house.address}</td>
                              <td>{house.city}</td>
                              <td>{house.state}</td>
                              <td>{house.zipcode}</td>
                              <td>{house.rooms.length}</td>
                              <td><Link to= {`/update/${house._id}/house`}>📝</Link></td>
                              <td><a onClick={()=>deleteHouse(house._id)}>🗑️</a></td>
                            </tr>
                          ))}
                        </tbody>
                </table>
                <button className="addItem"><Link to="/new?type=house">
                    <IoAddCircleSharp size={"1.2em"}/>
                    </Link>
                </button>
            </div>
            <button  onClick={
              renderUsers}
              >
                Users
                <FaArrowRight className="arrow"/>
            </button>
            <div className={users ? "userTable" : "hide"}>
                <div className="close-icon" onClick={() => setUsers(false)}>
                    <IoCloseCircleOutline size={"2em"}/>
                </div>
                <table>
                    {/* Table content for Users */}
                    <thead>
                            <tr>
                                <th>ID</th>
                                <th>USERNAME</th>
                                <th>EMAIL</th>
                                <th>ADMIN</th>
                                <th>EDIT</th>
                                <th>DELETE</th>
                            </tr>
                        </thead>
                        <tbody>
                        {userArr && userArr.length > 0 ? (
                          userArr.map((user) => (
                            <tr key={user._id}>
                              <td>{user._id}</td>
                              <td>{user.username}</td>
                              <td>{user.email}</td>
                              {user.isAdmin ? <td>Yes</td> : <td>No</td>}
                              <td><Link to={`/user/${user._id}`}>📝</Link></td>
                              <td><a onClick={() => deleteUser(user._id)}>🗑️</a></td>
                            </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="6">No users available</td>
                          </tr>
                        )}
                        </tbody>
                </table>
                <button className="addItem" ><Link to="/user">
                    <IoAddCircleSharp size={"1.2em"}/>
                    </Link></button>
            </div>
        </div>
    )
}
export default Admin;