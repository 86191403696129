import axios from "axios";
import { config } from "../utils/helpers";
import Cookies from "js-cookie";
export const API_BASE_URL = "https://api.citadel-cyber.dev";

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// USER INFORMATION
export const fetchLoggedInUser = async (email, password) => {
  const response = await api.post("/api/users/login", {
    email,
    password,
  });
  if (response.status === 200) {
    const oneDayInSeconds = 60 * 60 * 24; // 1 day in seconds
    const token = response.data.token;
    Cookies.set("token", token, {
      expires: oneDayInSeconds,
      secure: true,
      httpOnly: true,
    });
    return { token, response };
  } else {
    return { token: null, status: response.status, response };
  }
};
export const fetchOneUserData = async (storedToken, userId) => {
  try {
    const response = await api.get(`/api/users/${userId}`, {
      headers: {
        Authorization: `${storedToken}`,
      },
    });
    const resposeData = response.data;
    return resposeData;
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};
export const fetchAllUserData = async (token) => {
  try {
    const response = await api.get(`/api/users`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    const resposeData = response.data;
    return resposeData;
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};
export const createNewUserData = async ({
  admin,
  email,
  username,
  password,
  isAdmin,
  storedToken,
}) => {
  try {
    if (admin && admin.isAdmin) {
      const response = await api.post(
        "/api/users/",
        {
          username,
          email,
          password,
          isAdmin,
        },
        {
          headers: {
            Authorization: `${storedToken}`,
          },
        }
      );
    } else {
      console.error("User is not authorized to update house data.");
    }
  } catch (err) {
    console.error("Error creating new user:", err);
    throw err;
  }
};
export const updateUserData = async ({
  admin,
  id,
  username,
  email,
  password,
  isAdmin,
  storedToken,
}) => {
  try {
    if (admin && admin.isAdmin) {
      const response = await api.put(
        `/api/users/${id}`,
        {
          username,
          email,
          password,
          isAdmin,
        },
        {
          headers: {
            Authorization: `${storedToken}`,
          },
        }
      );
    } else {
      console.error("User is not authorized to update house data.");
    }
  } catch (err) {
    console.error("Error updating user:", err);
    throw err;
  }
};
export const deleteUserData = async (userId, adminUser, storedToken) => {
  try {
    if (adminUser && adminUser.isAdmin) {
      const response = await api.delete(`/api/users/${userId}`, {
        headers: {
          Authorization: `${storedToken}`,
        },
      });
    } else {
      console.error("User is not authorized to update house data.");
    }
  } catch (err) {
    console.error("Error updating user:", err);
    throw err;
  }
};
//ROOM INFORMATION
export const fetchAllRoomData = async (storedToken) => {
  try {
    const response = await api.get("/api/room", {
      headers: {
        Authorization: `${storedToken}`,
      },
    });
    const resposeData = response.data;
    return resposeData;
  } catch (error) {
    throw error;
  }
};
export const fetchOneRoomData = async (roomId, storedToken) => {
  try {
    const response = await api.get(`/api/room/${roomId}`, {
      headers: {
        Authorization: `${storedToken}`,
      },
    });
    const resposeData = response.data;
    return resposeData;
  } catch (error) {
    console.error("Error fetching room data:", error);
  }
};
export const createNewRoomData = async ({
  houseId,
  jobNumber,
  room,
  level,
  wired,
  description,
  storedToken,
}) => {
  try {
    const response = await api.post(
      "/api/room/",
      {
        HouseId: houseId,
        jobNumber,
        name: room,
        level,
        wired: wired,
        description,
      },
      {
        headers: {
          Authorization: `${storedToken}`,
        },
      }
    );
    console.log(response);
  } catch (err) {
    console.error("Error creating new room:", err);
    throw err;
  }
};
export const updateRoomData = async (roomId, formData, storedToken) => {
  try {
    const response = await api.put(
      `/api/room/${roomId}`,
      {
        jobNumber: formData.jobNumber,
        name: formData.name,
        level: formData.level,
        wired: formData.wired,
        description: formData.description,
      },
      {
        headers: {
          Authorization: `${storedToken}`,
        },
      }
    );
    console.log(response);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const deleteRoomData = async (roomId, storedToken) => {
  try {
    const response = await api.delete(`/api/room/${roomId}`, {
      headers: {
        Authorization: `${storedToken}`,
      },
    });
    console.log(response);
  } catch (err) {
    console.error("Error removing room:", err);
    throw err;
  }
};
//HOUSE INFORMATION
export const fetchAllHouseData = async (storedToken) => {
  try {
    const response = await api.get("/api/home", {
      headers: {
        Authorization: `${storedToken}`,
      },
    });
    const resposeData = response.data;
    return resposeData;
  } catch (err) {
    if (err.response) {
      // Check for specific status codes
      if (err.response.status === 401) {
        console.log("Unauthorized: Invalid or expired token");
        // Perform actions for an invalid token here, like redirecting to login
        window.location.pathname("/");
      } else {
        console.log("Other status code:", err.response.status);
        // Handle other status codes accordingly
      }
    } else {
      console.log("Network error or other issue:", err.message);
      // Handle network errors or other issues
    }
  }
};
export const fetchOneHouseData = async (houseId, storedToken) => {
  try {
    const response = await api.get(`/api/home/${houseId}`, {
      headers: {
        Authorization: `${storedToken}`,
      },
    });
    const resposeData = response.data;
    return resposeData;
  } catch (error) {
    throw error;
  }
};
export const associateHouseUser = async (houseId, userId, storedToken) => {
  try {
    await api.post(
      "/api/home/addUser",
      {
        userId,
        houseId,
      },
      {
        headers: {
          Authorization: `${storedToken}`,
        },
      }
    );
  } catch (err) {
    console.error("Error associating house with user", err);
    throw err;
  }
};
export const createNewHouseData = async ({
  userId,
  address,
  city,
  state,
  zipcode,
  storedToken,
}) => {
  try {
    await api.post(
      "/api/home/",
      {
        userId,
        address,
        city,
        state,
        zipcode,
      },
      {
        headers: {
          Authorization: `${storedToken}`,
        },
      }
    );
  } catch (err) {
    console.error("Error creating new house:", err);
    throw err;
  }
};
export const updateHouseData = async (id, formData, user, storedToken) => {
  try {
    if (user && user.isAdmin) {
      const response = await api.put(
        `/api/home/${id}`,
        {
          address: formData.address,
          city: formData.city,
          state: formData.state,
          zipcode: formData.zipcode,
        },
        {
          headers: {
            Authorization: `${storedToken}`,
          },
        }
      );
    } else {
      console.error("User is not authorized to update house data.");
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
// deltes house from user data
export const deleteHouseData = async (houseId, storedToken) => {
  try {
    const response = await api.delete(`/api/home/${houseId}`, {
      headers: {
        Authorization: `${storedToken}`,
      },
    });
    console.log(response);
  } catch (err) {
    console.error("Error removing house:", err);
    throw err;
  }
};
export const deleteHouseAdminData = async (houseId, admin, storedToken) => {
  try {
    if (admin && admin.isAdmin) {
      const response = await api.delete(`/api/home/admin/${houseId}`, {
        headers: {
          Authorization: `${storedToken}`,
        },
      });
    } else {
      console.error("User is not authorized to update house data.");
    }
  } catch (err) {
    console.error("Error removing house:", err);
    throw err;
  }
};
