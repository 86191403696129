import { Link, useRouteError } from "react-router-dom";
import { withAuth } from "../utils/auth";
const Error = () => {
    const error = useRouteError();
    console.error(error);
    const { storedToken } = withAuth();

    const renderLink = () => {
      if (storedToken) {
        // User has a token, link to the home page
        return <Link to="/home">Home</Link>;
      } else {
        // User does not have a token, link to the login page
        return <Link to="/">Login</Link>;
      }
    }
    
    return(
        <div>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
            <i>{error.statusText || error.message}</i>
            </p>
            <h1>Go Back to {renderLink()}</h1>
        </div>
    )
}
export default Error