import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils/helpers";
import PageContent from "./PageContent";

const Page = ({currentPage}) => {
    currentPage = currentPage.substring(1);

    useEffect(()=> {
        document.title = capitalizeFirstLetter(currentPage)
    },[currentPage]);

    return(
        <section>
            <PageContent>
                <Outlet/>
            </PageContent>
        </section>
    )
}

export default Page;