import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import RoomCard from "./RoomCard";
import { Link } from "react-router-dom";
import { BsFillTrash3Fill } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { IoCheckmarkDoneCircleSharp, IoAddCircleOutline } from "react-icons/io5";
import { fetchOneHouseData, fetchOneRoomData,deleteRoomData } from "../utils/api";
import Confirm from "./Confirm";
import { withAuth } from "../utils/auth";
import "../assets/CSS/House.css"

const House = () => {
    const [rooms, setRooms] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const {storedToken, userId } = withAuth();
    const navigate = useNavigate();
    const house = useLocation().state?.home || {};

    const showEdit = () => {
       const editDelContianer = document.querySelectorAll(".editDeleteBtnContainer")
       editDelContianer.forEach(container => {
          container.classList.remove("transparent")
       })

      }
      const hideEdit = () => {
        const editDelContianer = document.querySelectorAll(".editDeleteBtnContainer")
        editDelContianer.forEach(container => {
          container.classList.add("transparent")
       })
      }
      const openDeleteModal = (item) => {
        setSelectedItem(item);
        setDeleteModalOpen(true);
      };

      const closeDeleteModal = () => {
        setSelectedItem(null);
        setDeleteModalOpen(false);
      };
      const deleteRoom = async () => {
        if(selectedItem) {
          await deleteRoomData(selectedItem._id,storedToken);
          await new Promise(resolve => setTimeout(resolve, 500));
          navigate("/home");
        }
      } 
 
    useEffect(()=> {
      async function fetchData() {
        try {
          const homeData = await fetchOneHouseData(house._id, storedToken);

          const newRooms = homeData.rooms;
          const roomPromises = newRooms.map((room) => fetchOneRoomData(room,storedToken));
          const roomData = await Promise.all(roomPromises);
          setRooms([...rooms, ...roomData]);
        } catch (error) {
          console.error("Error fetching house data:", error);
        }
      }
      fetchData();
    },[house._id])
    const hasRooms = house.rooms && house.rooms.length > 0;
    return(
        // this will have all the rooms witin the couches using the map to fetch all of the rooms that are matching with the 
        // there will be two ways to look at it through a visual item => on click goes to a modal loading in the data or a list that is clicked and you can order it though name or level that can be clicked => and the same modal opens
        //add button will create an new Room
        // if there are no rooms at this place then add a room 
        <div className="houseContainer">
            <h1>{house.address}, {house.city},{house.state} {house.zipcode}</h1>
            {hasRooms ? (
            <section className="cardContainer gridsForCards">
            {rooms.map((room) => (
              <div key={room._id} >
              <RoomCard room={room}/>
              <div className="editDeleteBtnContainer roomEditDelete transparent">
              <Link to={{pathname:`/update/${room._id}/room/`, search:`?houseAddress=${house._id}`}}>
                <button><AiOutlineEdit/></button>
              </Link>
              <button onClick={()=>openDeleteModal(room)}><BsFillTrash3Fill/></button>
              <button onClick={hideEdit}><IoCheckmarkDoneCircleSharp/></button>  
            </div>
              </div>
              
            ))}
          </section>
            ):(
              <div className="noRoomsDiv d-center">
              <h1>Let's Add Rooms</h1>
              </div>
            )}
            <Confirm
            item = "Room"
            isOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            onDelete={deleteRoom}
            />
            <div className="float-R floatConatiner ">
              <a className="addItemButton d-center drop-shadow" onClick={showEdit}> <AiOutlineEdit/></a>
             <Link to="/new?type=room" className="addItemButton d-center drop-shadow"><IoAddCircleOutline/></Link>
            </div>
        </div>
    )
}
export default House