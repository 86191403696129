import "../assets/CSS/Confirm.css"

const Confirm = ({ isOpen, onClose, onDelete, item }) => {
  if (!isOpen) return null;

  return (
    <div className="modalContainer d-center confirmContainer">
        <div className="modal d-center-column centerItem">
        <h1>Are you sure you want to delete this {item}?</h1>
        <section>
          <button onClick={onDelete}>Yes</button>
          <button onClick={onClose}>No</button>
        </section>
      </div>
    </div>
  );
};

export default Confirm;
