import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { fetchOneUserData } from "../utils/api";
import Cookies from "js-cookie";

// export const isTokenExpired = (cookieName) => {
//   const storedToken = Cookies.get(cookieName);
//   if (!storedToken) {
//     return true;
//   }
//   const cookieExpires = new Date(Cookies.get(cookieName + "_expires"));
//   const currentTime = new Date();
//   if (cookieExpires < currentTime) {
//     return true;
//   } else {
//     return false;
//   }
// };
export const withAuth = () => {
  try {
    const storedToken = Cookies.get("token");
    if (!storedToken) {
      return { error: "Token not found" };
    }
    const decodedToken = jwt_decode(storedToken);

    //checking  if token is expired
    const currentTime = Math.floor(Date.now() / 1000);
    if (decodedToken.exp && decodedToken.exp < currentTime) {
      return { error: "Token expired" };
    }

    const userId = decodedToken.sub;
    return { storedToken, userId };
  } catch (err) {
    console.error("Error decoding token:", err);
    return { error: "Invalid token" };
  }
};
export const checkAdminPriv = async () => {
  const storedToken = Cookies.get("token");
  const decodedToken = jwt_decode(storedToken);
  const userId = decodedToken.sub;
  let checkAdmin;
  try {
    const userData = await fetchOneUserData(storedToken, userId);
    if (userData && userData.isAdmin !== undefined) {
      checkAdmin = userData.isAdmin;
      return checkAdmin;
    } else {
      console.error("Invalid user data:", userData);
      // Handle unexpected response or missing data
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    // Handle fetch error
  }
};
