import "../assets/CSS/Login.css";
import React, {useState} from 'react';
import { fetchLoggedInUser } from "../utils/api";
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import logo from "../assets/Images/logo.png"
import Cookies from "js-cookie";

const Login = () => {
    // uses the fetch of login to get the right cred
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [password, setPassword] = useState('');
    const [wrongPassword, setWrongPassword] = useState(false);
    const navigate = useNavigate();

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const {token,response} = await fetchLoggedInUser(email,password)
            if (response.status === 200) {
                const decodedToken = jwt_decode(token);
                if (decodedToken) {
                    const userId = decodedToken.userId;
                    Cookies.set("token", token, {expires:7});
                    navigate('/home', { state: { userId } });
                }
            }
        }catch(err){
            if(err.response.status === 401) {
                setWrongPassword(true);
                setErrorMessage('Invalid email or password. Please try again.');
                setEmail('');
                setPassword('');
            }else {
                setErrorMessage('An error occurred. Please try again later.');
            }
        }    

    };
    const loginContainerClass = wrongPassword ? "loginContainer shake" : "loginContainer";

    return(
        <div className="loginArea">
        <div className={loginContainerClass}>
            <section>
            <div className="drop-shadow">
        <form className="loginForm " onSubmit={handleFormSubmit}>
        <img src={logo} alt="Citadel Cyber Logo" />
            <div>
            <label htmlFor="email">EMAIL</label>
            <input 
                type="email" 
                placeholder="citadelcyber@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            ></input>
            </div>
            <div>
            <label htmlFor="password">PASSWORD</label>
            <input 
                type="password" 
                name="password" 
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            ></input>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button type="submit">LOGIN</button>
        </form>
        </div>
        </section>
        </div>
        </div>
    )
}
export default Login;