import {Link, useNavigate } from "react-router-dom";
import {setThemes} from "../../utils/themes";
import ItemType from "../TypeModal";
import { useState, useEffect } from "react";
import { fetchOneUserData} from "../../utils/api";
import { withAuth } from "../../utils/auth";
import Cookies from "js-cookie";
import "../../assets/CSS/DarkLightMode.css"


const Navbar = () => {
    const navigate = useNavigate();
    const [toggle, setToggle] = useState('dark');
    const [isTypeModalOpen, setTypeModalOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const {storedToken, userId,error} = withAuth();
    if(error){
      navigate("/error")
    }
    const checkAdminPriv = async () => {
      try {
        const userData = await fetchOneUserData(storedToken, userId);
        if (userData && userData.isAdmin !== undefined) {
          setIsAdmin(userData.isAdmin)
        } else {
          console.error('Invalid user data:', userData);
          // Handle unexpected response or missing data
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle fetch error
      }
    }
    checkAdminPriv();
    
    const openModal = () => {
      setTypeModalOpen(true);
    }

    const closeModal = () => {
      setTypeModalOpen(false);
    }
    const handleToggleTheme = () => {
      if (toggle === 'light') {
        setThemes('theme-dark');
        setToggle('dark');
      } else {
        setThemes('theme-light');
        setToggle('light');
      }
    };
    const handleLogout = () => {
      Cookies.remove('token')
      window.location.href = '/';
    };
    const handleSelectType = (type)=> {
      closeModal()
      navigate("/new?type=" + type)
    }
    const handleOnClick = () => {
      if (localStorage.getItem('theme') === 'theme-dark') {
          setThemes('theme-light');
          setToggle('light')
      } else {
          setThemes('theme-dark');
          setToggle('dark')
      }
    }

    useEffect(() => {
      if (localStorage.getItem("theme") === "theme-light") {
        setToggle("light");
      }
    }, []);

    return(
        <nav>
            <ul className="flex-row">
              <a onClick={openModal}>Add</a>
              {isAdmin ? <Link to={"/admin"}>Admin</Link>: null}
              
              <a onClick={handleLogout}>Logout</a>
            </ul>
            {isTypeModalOpen && (
              <ItemType isTypeOpen={isTypeModalOpen} isTypeModalClose={closeModal} onSelectType={handleSelectType} adminPriv = {isAdmin}/> 
            )}
            <div className={`container--toggle ${toggle}`}>
                <div>
                    <input 
                    type="checkbox" 
                    id="toggle" className="toggle--checkbox" 
                    onClick={handleOnClick} 
                    onChange={handleToggleTheme}
                    checked={toggle === "light"}/>
                    <label htmlFor="toggle" className={`toggle--label ${toggle === "dark" ? "moon" : ""}`}>
                      <span className="toggle--label-background"></span>
                    </label>
                </div>
        </div>
        </nav>

    )
}
export default Navbar