import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsFillTrash3Fill } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { IoCheckmarkDoneCircleSharp, IoAddCircleOutline } from "react-icons/io5";
import jwt_decode  from 'jwt-decode';
import { withAuth } from "../utils/auth";
import { fetchOneUserData, fetchAllHouseData, deleteHouseData, associateHouseUser, deleteHouseAdminData} from "../utils/api";
import Confirm from "./Confirm";

const HomePage = () => {
    // uses Cards.jsx to map over data and render the page there will be a grey ad button in the bottom corner to click and create a neeItem ({house})
    const navigate = useNavigate();
    const [homes, setHomes] = useState([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [houseType, setHouseType] = useState('my')
    const [allHouses, setAllHouses] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const {storedToken, userId, error} = withAuth();

    if(error){
      navigate("/error")
    }
    const checkAdminPriv = async () => {
      try {
        const userData = await fetchOneUserData(storedToken, userId);
        if (userData && userData.isAdmin !== undefined) {
          setIsAdmin(userData.isAdmin)
        } else {
          console.error('Invalid user data:', userData);
          // Handle unexpected response or missing data
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle fetch error
      }
    }
    checkAdminPriv()

      const fetchAllHouses = async () => {
        setHouseType("all")
        try {
          const houseData = await fetchAllHouseData(storedToken);
          setHomes(houseData)
          setAllHouses(true)
        } catch(err) {
          console.error(err);
        }
      }
      const fetchMyHouses = async () => {
        // hideEdit();
        setHouseType("my")
        if (!storedToken) {
          navigate('/error');
        } else {  
          try {
            setAllHouses(false);
            const decodedToken = jwt_decode(storedToken);
            const userId = decodedToken.sub;
            const userData = await fetchOneUserData(storedToken, userId);
            if (userData && userData.houses) {
              const userHouses = userData.houses;
              setHomes(userHouses)
            } else {
              console.error('Data is missing.');
            }
          } catch (err) {
            console.error('Error fetching house data:', err);
          }
        }
      }

      const byState = () => {
        const sortedHomes = [...homes];
        sortedHomes.sort((a, b) => {
          const stateA = a.state.toLowerCase();
          const stateB = b.state.toLowerCase();
          return stateA.localeCompare(stateB);
        });
        setHomes(sortedHomes);
      }

      const byAddress = () => {
        const sortedHomes = [...homes];
          sortedHomes.sort((a, b) => {
            const addressA = a.address.toLowerCase();
            const addressB = b.address.toLowerCase();
            return addressA.localeCompare(addressB);
          });
        setHomes(sortedHomes);
      };

      const byRooms = () => {
        const sortedHomes = [...homes];
        sortedHomes.sort((a, b) => {
          return a.rooms.length - b.rooms.length;
        });;
      
        setHomes(sortedHomes);
      };
      
      const showEdit = () => {
       const editDelContianer = document.querySelectorAll(".editDeleteBtnContainer")
       editDelContianer.forEach(container => {
          container.classList.remove("transparent")
       })

      }
      const hideEdit = () => {
        const editDelContianer = document.querySelectorAll(".editDeleteBtnContainer")
        editDelContianer.forEach(container => {
          container.classList.add("transparent")
       })
      }
     
      const openDeleteModal = (item) => {
        setSelectedItem(item);
        setDeleteModalOpen(true);
      };

      const closeDeleteModal = () => {
        setSelectedItem(null);
        setDeleteModalOpen(false);
      };
      const addToMyHouses = async (id) => {
        await associateHouseUser(id,userId,storedToken);
        hideEdit();
        fetchMyHouses();
        navigate("/home");
      }
      const deleteHouse = async () => {
        if (selectedItem && houseType === "all") {
          const userData = await fetchOneUserData(storedToken, userId);
          deleteHouseAdminData(selectedItem._id, userData,storedToken)
          await new Promise(resolve => setTimeout(resolve, 500));
        } else if(selectedItem) {
          deleteHouseData(selectedItem._id,storedToken);
          await new Promise(resolve => setTimeout(resolve, 500));
        } 
        hideEdit();
        fetchMyHouses();
        navigate("/home");
        setTimeout(()=> {
          setDeleteModalOpen(false)
        },200)
      } 
      useEffect(() => {
        fetchMyHouses();
      }, []);
      
    return(
        <div>
            <h1>{houseType === 'my' ? "My Properties" : "All Properties"}</h1>
            <h3>{homes.length}</h3>
            <div className="houseLinkContainer d-even">
              <a onClick={fetchAllHouses}>All Houses</a>
              <a onClick={fetchMyHouses}>My Houses</a>
            </div>
            <h5><u>Categories</u></h5>
            <div className="houseLinkContainer categoryContainer">
              <button onClick={byState}>State</button> 
              <button onClick={byAddress}>Address</button>
              <button onClick={byRooms}>Rooms</button>  
            </div>
            {homes.length > 0 ? (
          <section className="homeContainer gridsForCards">
            {homes.map((home) => (
              <div key={home._id}>
                  <Link to="/house" state={{ home }} key={home._id}>
                    <button className="homeCard drop-shadow">
                    <h5>
                      {home.city}, {home.state}
                    </h5>
                    <h3>{home.address}</h3>
                    <p className="roomContent drop-shadow">
                      {home.rooms.length} Rooms
                    </p>
                  </button>
                </Link>
                <section className="editDeleteBtnContainer transparent">
                {allHouses && (
                  <button onClick={() => addToMyHouses(home._id)}>
                    <IoAddCircleOutline/>
                  </button>
                )}
                {isAdmin ? 
                <>
                <Link to={{ pathname: `/update/${home._id}/house`, state: { formData: home }}}>
                  <button>
                    <AiOutlineEdit/>
                  </button>
                </Link>
                  <button onClick={()=>openDeleteModal(home)}><BsFillTrash3Fill/></button>
                  <button onClick={hideEdit}><IoCheckmarkDoneCircleSharp/></button>
                </>
                : null}
                </section>
              </div>
        ))}
      </section>
    ) : (
      <div>
        <h1>Add Homes</h1>
        <p>Go to All Houses</p>
      </div>
    )}  
           <Confirm
            item = "House"
            isOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            onDelete={deleteHouse}
            />
        
            <div className="float-R floatConatiner">
            <a className="addItemButton d-center drop-shadow" onClick={showEdit}> <AiOutlineEdit/></a>
            {isAdmin ? <Link to="/new?type=house" className="addItemButton d-center drop-shadow"><IoAddCircleOutline/></Link> : null}
             </div>
        </div>
    )
}
export default HomePage