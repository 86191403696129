import React, { useEffect } from 'react'
import { createBrowserRouter, RouterProvider} from 'react-router-dom';
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import Error from './components/Error.jsx';
import HomePage from './components/HomePage.jsx';
import Admin from './components/Admin.jsx';
import House from './components/House.jsx';
import UserForm from "./components/UserForm.jsx";
import RoomCard from './components/RoomCard.jsx';
import './index.css'
import NewItem from './components/NewItem.jsx';
import UpdateItem from './components/UpdateItem.jsx';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import Login from './components/Login.jsx';
import { withAuth } from './utils/auth.js';
Amplify.configure(config);


const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Login/>,
      },
      {
        path: 'home',
        element: <HomePage/>,
      },
      {
        path: 'house',
        element: <House />,
      },
      {
        path: 'room',
        element: <RoomCard />,
      },
      {
        path: 'new',
        element: <NewItem />,
      },
      {
        path: 'admin',
        element:<Admin/>,
      },
      {
        path: 'user',
        element:<UserForm/>,
      },
      {
        path: 'user/:id',
        element: <UserForm />,
      },
      {
        path: 'update/:id/:type',
        element: <UpdateItem />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />
)
