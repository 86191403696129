import { useState, useEffect } from "react";
import { createNewUserData, fetchOneUserData,updateUserData } from "../utils/api";
import { useParams,useNavigate } from "react-router-dom";
import { withAuth } from "../utils/auth";
import "../assets/CSS/NewItem.css";
const UserForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isAdmin, setIsAdmin] = useState();
    let { storedToken, userId } = withAuth();

    const handleNewUserSubmit = async (e) => {
        e.preventDefault();
        try {
            let admin = await fetchOneUserData(storedToken,userId)
            if(id){
                await updateUserData({
                    admin,
                    id,
                    username,
                    email,
                    password,
                    isAdmin,
                    storedToken
                  });
            } else {
                await createNewUserData({admin,username,email,password,isAdmin,storedToken
                });
            }
            navigate("/admin")
          } catch (error) {
            console.error('Error logging in:', error);
          }
    }
    useEffect(() => {
        if (id) {
          // Fetch user data to pre-populate the form if `id` exists
          const fetchData = async () => {
            try {
              const userData = await fetchOneUserData(storedToken, id);
              setUsername(userData.username);
              setEmail(userData.email);
              setIsAdmin(userData.isAdmin);
            } catch (error) {
              console.error('Error fetching user data:', error);
            }
          };
          fetchData();
        }
      }, [id, storedToken]);
    return(
    <div className="newItemContainer d-center">
        <form onSubmit={(e) =>handleNewUserSubmit(e)}>
            <h1>Add A User</h1>
            <label htmlFor="username">Username</label>
            <input 
            type="type" 
            placeholder="citadel cyber lion"
            value={username}
            onChange={(e) => setUsername(e.target.value)} required></input>
            <label htmlFor="email">Email</label>
            <input 
            type="type" 
            placeholder="citadel@cyber.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)} required></input>
            <label htmlFor="password">Password</label>
            <input 
            type="type" 
            placeholder="password1"
            value={password}
            onChange={(e) => setPassword(e.target.value)} required></input>
            <label htmlFor="connectionType">Admin Privlages</label>
            <select htmlFor="connectionType"
            value={isAdmin}
            onChange={(e) => setIsAdmin(e.target.value)}  required>
                <option value="wired">Options</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
            </select>
            <button>Add User</button>
        </form>
    </div>
)}
export default UserForm;