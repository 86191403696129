import React, {useEffect, useState} from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { states } from '../utils/lists';
import "../assets/CSS/NewItem.css"
import { createNewHouseData, fetchAllHouseData, createNewRoomData } from "../utils/api";
import {withAuth} from "../utils/auth"
import Cookies from "js-cookie";
import jwt_decode from 'jwt-decode';


const NewItem = () => {
    // type either means house or room
    // first figure out if they are making a room or a house and ask the questions it would be either a POSThouse or POST Room request
    // each question would be on an individual page

    // home page
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipcode] = useState('');

    //room page
    const [homes, setHomes] = useState([]);
    const [houseAddress, setHouseAddress] = useState('');
    const [jobNumber, setJobNumber] = useState('');
    const [roomType, setRoomType] = useState('Bedroom');
    const [level, setLevel] = useState('Attic');
    const [connectionType, setConnectionType] = useState('wired');
    const [description, setDescription] = useState('');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const newType = searchParams.get("type")
    let { storedToken, userId } = withAuth();

    const isTokenExpired = (cookieName) => {
        const storedToken = Cookies.get(cookieName);
    
        if (!storedToken) {
          return true;
        }
    
        const cookieExpires = new Date(Cookies.get(cookieName + '_expires'));
        const currentTime = new Date();
    
        if (cookieExpires < currentTime) {
          return true;
        } else {
          return false;
        }
      };
    
    const decodeToken = () => {
        const storedToken = Cookies.get("token");
        if (!storedToken || isTokenExpired("token")) {
          navigate('/'); // Redirect to the login page if the token is missing
        } else {
          const decodedToken = jwt_decode(storedToken);
          userId = decodedToken.userId;
          return userId
        }

    }
    const handleNewHomeSubmit = async (e) => {
        e.preventDefault();
        const {storedToken, userId} = withAuth();
        if(userId){
        try {
            const newHome = await createNewHouseData({userId:userId.toString(),address,city,state,zipcode, storedToken});
            console.log("created New home", newHome)
            navigate("/home")
          } catch (error) {
            console.error('Error logging in:', error);
          }
        } else {
            console.log("User not authenticated")
        }
    };
    const handleNewRoomSubmit = async (e) => {
        e.preventDefault();
        let wiredOutcome = connectionType === "wired" ? true : false;
        try {
            await createNewRoomData({
                houseId:houseAddress.toString(),
                jobNumber,         
                room:roomType,
                level,
                wired: wiredOutcome, 
                description,
                storedToken
            });
            navigate("/home")
          } catch (error) {
            console.error('Error logging in:', error);
          }
    }
    useEffect(()=> {
        async function fetchData() {
            try{
                const houseData = await fetchAllHouseData(storedToken)
                setHomes(houseData)

            }catch (err) {
                console.log(err)
            }
        }
        fetchData();
    },[])

    return(
        <div className="newItemContainer d-center">
            {newType === "house" ? (
                <form onSubmit={handleNewHomeSubmit}>
                    <h1>Add A House</h1>
                    <input 
                    type="text" 
                    placeholder="Address"
                    onChange={(e) => setAddress(e.target.value)}
                    required></input>
                    
                    <input type="text" placeholder="City" onChange={(e) => setCity(e.target.value)}  required></input>
                    <select name="room" value={state} onChange={(e) => setState(e.target.value)}  required>
                        {states.map(state => (
                            <option key={state} value={state}>{state}</option>
                        ))}
                    </select>
                    <input type="text" placeholder="Zipcode" onChange={(e) => setZipcode(e.target.value)}  required></input>
                    <button>Add House</button>
                </form>    
            ):(
                <form onSubmit={(e) =>handleNewRoomSubmit(e, roomType)}>
                    <h1>Add A Room</h1>
                    <label htmlFor="Address">Address</label>
                    <select name="room" value={houseAddress} onChange={(e) => setHouseAddress(e.target.value)}  required>
                        <option>Address</option>
                    {homes.map(home => (
                        <option key={home._id} value={home._id}>
                            {`${home.address}, ${home.city}, ${home.state}, ${home.zipcode}`}

                        </option>
                        ))}
                    </select>
                    <label htmlFor="jobNumber">Job Number</label>
                    <input 
                    type="type" 
                    placeholder="JB7869"
                    value={jobNumber}
                    onChange={(e) => setJobNumber(e.target.value)}  required></input>
                    <label htmlFor="Room">Room</label>
                    <select name="room"
                    value={roomType}
                    onChange={(e)=> setRoomType(e.target.value)}
                    required
                    >
                        <option value="Bedroom">Bedroom</option>
                        <option value="Living Room">Living Room</option>
                        <option value="Kitchen">Kitchen</option>
                        <option value="Bathroom">Bathroom</option>
                        <option value="Garage">Garage</option>
                        <option value="Attic">Attic</option>
                        <option value="Dining Room">Dining Room</option>
                    </select>
                    <label htmlFor="Level">Level</label>
                    <select
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}  required>
                        <option value="Attic">Attic</option>
                        <option value="1st">1st</option>
                        <option value="2nd">2nd</option>
                        <option value="3rd">3rd</option>
                        <option value="Basement">Basement</option>
                    </select>
                    <label htmlFor="connectionType">Connection Type</label>
                    <select htmlFor="connectionType"
                    value={connectionType}
                    onChange={(e) => setConnectionType(e.target.value)}  required>
                        <option value="wired">Ethernet</option>
                        <option value="not wired">Wireless</option>
                    </select>
                    <label htmlFor="Description">Description</label>
                    <input 
                    type="type" 
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}  required></input>
                    <button>Add Room</button>
                </form>   
            )}
        </div>
    )
}
export default NewItem