import React, { useState, useEffect } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import { fetchAllHouseData, updateHouseData, updateRoomData, fetchOneRoomData, fetchOneHouseData, fetchOneUserData } from '../utils/api';
import {checkAdminPriv, withAuth} from '../utils/auth';
import { states } from '../utils/lists';
import "../assets/CSS/NewItem.css"

const UpdateItem = () => {
  const { id, type } = useParams();
  const [homes, setHomes] = useState([]);
  const navigate = useNavigate();
  let { storedToken, userId } = withAuth();
// console.log(id)
  let isAdmin = checkAdminPriv();  

  const [formData, setFormData] = useState({
    id:'',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    houseAddress: '',
    jobNumber: '',
    roomType: '',
    level: '',
    connectionType: '',
    description: '',
  });
  const fetchItem = async () => {
    let itemData = null;
    
    try {
      if (type === "house") {
        itemData = await fetchOneHouseData(id, storedToken);
      } else {
        itemData = await fetchOneRoomData(id, storedToken);
      }
      if (itemData) {
        setFormData({
          ...formData,
          ...itemData,
        });
      }
    } catch (error) {
      console.error('Error fetching item data:', error);
    }
  }  

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if(type === 'house') {
        try {
            console.log(id)
            let user = await fetchOneUserData(storedToken, userId);
            await updateHouseData(id,formData, user,storedToken);
            navigate('/home'); 
          } catch (error) {
            console.error('Error updating item:', error);
          }
    } else {
        try {
            await updateRoomData(id, formData, storedToken);
            navigate('/home'); 
          } catch (error) {
            console.error('Error updating item:', error);
        }
    }
  }
  useEffect(() => {
    const fetchHouses = async () => {
        try{
            const houseData = await fetchAllHouseData(storedToken)
            setHomes(houseData)

        }catch (err) {
            console.log(err)
        }
    }
    if(type === "room") {
        fetchHouses()
    }
    fetchItem();
  }, [id, type]);

  return (
    <div >
      <h1>Update {type === 'house' ? 'House' : 'Room'}</h1>
      <div className="newItemContainer d-center">
        {type === "house" ? (
                <form onSubmit={handleUpdateSubmit}>
                <input 
                    type="text" 
                    placeholder="Address"
                    value={formData.address}
                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                    ></input>
                    <input 
                    type="text" 
                    placeholder="City" 
                    value={formData.city}
                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}></input>
                    <select name="room" value={formData.state}
              onChange={(e) => setFormData({ ...formData, state: e.target.value })}>
                        {states.map(state => (
                            <option key={state} value={state}>
                                {state}
                            </option>
                        ))}
                    </select>
                    <input type="text" placeholder="Zipcode" value={formData.zipcode}
              onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}></input>
              <button type='submit'>Update {type === 'house' ? 'House' : 'Room'}</button>
            </form>  
            ):(
                <form onSubmit={handleUpdateSubmit}>
                    <label htmlFor="Address">Address</label>
                    <select name="room" value={formData.houseAddress}
                    onChange={(e) => setFormData({ ...formData, houseAddress: e.target.value._id })}>
                    {homes.map(home => (
                        <option key={home._id} value={home._id}>
                            {`${home.address}, ${home.city}, ${home.state}, ${home.zipcode}`}

                        </option>
                        ))}
                    </select>
                    <label htmlFor="jobNumber" >Job Number</label>
                    <input 
                    type="type" 
                    placeholder="JB7869"
                    value={formData.jobNumber}
                    onChange={(e) => setFormData({ ...formData, jobNumber: e.target.value })}></input>
                    <label htmlFor="Room">Room</label>
                    <select name="room"
                    value={formData.roomType}
                    onChange={(e) => setFormData({ ...formData, roomType: e.target.value })}
                    >
                        <option value="Bedroom">Bedroom</option>
                        <option value="Living Room">Living Room</option>
                        <option value="Kitchen">Kitchen</option>
                        <option value="Bathroom">Bathroom</option>
                        <option value="Garage">Garage</option>
                        <option value="Attic">Attic</option>
                        <option value="Dining Room">Dining Room</option>
                    </select>
                    <label htmlFor="Level">Level</label>
                    <select
                    value={formData.level}
                    onChange={(e) => setFormData({ ...formData, level: e.target.value })}>
                        <option value="Attic">Attic</option>
                        <option value="1st">1st</option>
                        <option value="2nd">2nd</option>
                        <option value="3rd">3rd</option>
                        <option value="Basement">Basement</option>
                    </select>
                    <label htmlFor="connectionType">Connection Type</label>
                    <select htmlFor="connectionType"
                    value={formData.connectionType}
                    onChange={(e) => setFormData({ ...formData, connectionType: e.target.value })}>
                        <option value="wired">Ethernet</option>
                        <option value="not wired">Wireless</option>
                    </select>
                    <label htmlFor="Description">Description</label>
                    <input 
                    type="type" 
                    placeholder="Description"
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}></input>
                    <button type='submit'>Update {type === 'house' ? 'House' : 'Room'}</button>
                </form>
        )}
        </div>
    </div>
  );
};

export default UpdateItem;