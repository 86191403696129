import { Link } from "react-router-dom";
import NewItem from "./NewItem"
import "../assets/CSS/Type.css"

const TypeModal = ({isTypeOpen, isTypeModalClose, onSelectType, adminPriv}) => {
    if(!isTypeOpen) {
        return null;
    }

    return (
        <div className="modalContainer d-center">
            <div className="modal newItemModal d-center-coloumn">
                <button className="backBtn" onClick={isTypeModalClose}>Back</button>
                <h1>What are you trying to add?</h1>
                <div className="d-even">
                    {adminPriv ? <div className="d-even">
                    <button onClick={()=> onSelectType("house")}>House</button>
                    <button onClick={()=> onSelectType("room")}>Room</button>
                    </div>: <button onClick={()=> onSelectType("room")}>Room</button>}
                </div>
            </div>
        </div>
    )
}
export default TypeModal;